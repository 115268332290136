import { ConfigService } from "../resources/services/ConfigService";
import * as environment from "../../config/environment.json";
import { FhirService } from "../resources/services/FhirService";
import { RuntimeInfo } from "../resources/classes/RuntimeInfo";
import { UserService } from "../resources/services/UserService";
import { inject } from "aurelia-framework";
import { DialogService } from "aurelia-dialog";
import { Router } from "aurelia-router";
import { LocationService } from "resources/services/LocationService";

@inject(UserService, DialogService, Router, LocationService)
export class WardOverview {
  router: Router;
  locationService: LocationService;
  wardId = null;
  iframeUrl;
  iframeOrigin;
  iframeContainer;
  ward;
  config;
  userService;
  dialogService;
  dialogIsOpen = false;

  constructor(userService: UserService, dialogService: DialogService, router: Router, locationService: LocationService) {
    this.router = router;
    this.userService = userService;
    this.dialogService = dialogService;
    this.locationService = locationService;
  }

  activate(params) {
    if (params['id']) {
      this.wardId = params['id'];
      this.ward = LocationService.LocationById(this.wardId);
    }

    this.config = ConfigService.GetFormSettings(ConfigService.FormNames.WardOverview);
  }

  attached() {
    const loginData = sessionStorage.getItem(environment.sessionName);

    const query = {
      login: loginData,
      server: FhirService.Endpoint,
      ward: this.wardId,
      practitionerId: '',
      origin: window.location.origin,
    };

    if (this.userService.practitioner) {
      query.practitionerId = this.userService.practitioner.id;
    }

    this.iframeUrl = `${this.config.settings && this.config.settings.iframe && this.config.settings.iframe.url}?` + Object.keys(query).map((key) => {
      return `${key}=${encodeURIComponent(query[key])}`;
    }).join('&');
    this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';

    RuntimeInfo.TogglePatientListLG(true);
  }

  detached() {
    RuntimeInfo.TogglePatientListLG(false);
  }
}
